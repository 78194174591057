import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADMIN_ADEPT_DATA, ADMIN_MEASUREMENTS_CONFIG_MANAGE } from 'Consts/routes';

import { withVariables } from 'Utils/string';
import { parseQueryToObject } from 'Utils/querystring';

import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import Page from 'Components/layout/panel/Page';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import Editor from 'Components/admin/measurementsConfig/Editor';
import { TAB_MEASUREMENTS } from 'Components/pages/admin/AdeptData/component';

export const TAB_DATA = 'data';

export default class AdminMeasurementsConfigManage extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                id: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
        actions: PropTypes.shape({
            single: PropTypes.func.isRequired,
        }).isRequired,
        measurementConfig: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            isLoaded: PropTypes.bool.isRequired,
            data: PropTypes.object,
        }),
    };
    static defaultProps = {};

    getQueryConfig = (props = this.props) => {
        const { location } = props;
        const { search } = location;
        return parseQueryToObject(search, true);
    }

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const previousQueryObject = this.getQueryConfig(prevProps);
        const queryObject = this.getQueryConfig();

        if (JSON.stringify(previousQueryObject) !== JSON.stringify(queryObject)) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions } = this.props;
        const queryObject = this.getQueryConfig();

        actions.single({ id: queryObject.userId });
    }

    render() {
        const { measurementConfig, location, history } = this.props;
        const queryObject = this.getQueryConfig();

        return (
            <StyledComponent
                className="admin-measurements-config-manage"
                styles={require('./styles')}
            >
                <Page
                    elementMode={true}
                    data={measurementConfig}
                >
                    {measurementConfig && measurementConfig.data && (
                        <PageContent
                            breadcrumbs={[{
                                to: withVariables(ADMIN_ADEPT_DATA.path),
                                label: 'Kartoteka',
                            }, {
                                to: withVariables(ADMIN_ADEPT_DATA.path, {}, { tab: TAB_MEASUREMENTS }),
                                label: 'Pomiary',
                            }, {
                                to: withVariables(ADMIN_MEASUREMENTS_CONFIG_MANAGE.path, { id: measurementConfig.data.id }, { tab: TAB_DATA }),
                                label: 'Zarządzaj pomiarami',
                            }]}
                        >
                            <TabNavigation
                                location={location}
                                history={history}
                                headline="Zarządaj pomiarami"
                                tabs={[{
                                    key: TAB_DATA,
                                    label: 'Dane',
                                    children: (
                                        <Editor
                                            location={location}
                                            history={history}
                                            loadData={this.loadData}
                                            data={measurementConfig.data}
                                            userId={queryObject.userId}
                                        />
                                    ),
                                }]}
                            />
                        </PageContent>
                    )}
                </Page>
            </StyledComponent>
        );
    }
}

