import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { parseQueryToObject } from 'Utils/querystring';

import StyledComponent from 'Components/core/StyledComponent';
import ModalWrapper from 'Components/layoutAdmin/modals/ModalWrapper';
import ModalBody from 'Components/layoutAdmin/modals/ModalBody';
import ModalTitle from 'Components/layoutAdmin/modals/ModalTitle';
import NewType from 'Components/admin/measurementsConfig/NewType';

export default class AdminModalUserOfferDeletionEditor extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        onSuccess: PropTypes.func,
        isOpen: PropTypes.bool,
        onClose: PropTypes.func,
        data: PropTypes.object,
        predefinedState: PropTypes.object,
    };
    static defaultProps = {
        isOpen: false,
        onClose: null,
        onSuccess: null,
        data: null,
        predefinedState: {},
    };
    state = {};

    onSuccess = () => {
        const { onSuccess } = this.props;

        return onSuccess();
    }

    getQueryConfig = (props = this.props) => {
        const { location } = props;
        const { search } = location;
        return parseQueryToObject(search, true);
    }

    render() {
        const { location, history, isOpen, onClose, data, predefinedState } = this.props;
        const queryObject = this.getQueryConfig();

        return (
            <ModalWrapper
                location={location}
                history={history}
                isOpen={isOpen}
                onClose={onClose}
            >
                <StyledComponent className="admin-modal-user-offer-deletion" styles={require('./styles')}>
                    <ModalBody>
                        <ModalTitle title="Dodaj nowy typ pomiarów" />
                        <NewType
                            location={location}
                            history={history}
                            data={data}
                            onConfirm={onClose}
                            userId={queryObject.userId}
                        />
                    </ModalBody>
                </StyledComponent>
            </ModalWrapper>
        );
    }
}
