import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        .measurement-config-box-footer {
            .measurement-config-box-footer-control {
                cursor: pointer;
                color: #242424;
                font-size: 1em;
                font-weight: 600;
                display: block;
                text-align: right;

                &:hover {
                    color: black;
                }
            }
        }
    `;
