import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { MEASUREMENTS_CONFIG } from 'Consts/measurementConfig';

import { parseQueryToObject } from 'Utils/querystring';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layoutAdmin/Spinner';
import ElementEditor from 'Components/layoutAdmin/panel/ElementEditor';
import MeasurementConfigNewType from 'Components/admin/modals/MeasurementsConfigNewType';

export default class AdminMeasurementsConfigEditor extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            create: PropTypes.func.isRequired,
        }).isRequired,
        data: PropTypes.object,
        loadData: PropTypes.func,
        predefinedState: PropTypes.object,
        userId: PropTypes.string.isRequired,
        config: PropTypes.object,
    };

    static defaultProps = {
        data: null,
        predefinedState: {},
        loadData: () => null,
    };

    state = {
        formState: {},  //eslint-disable-line react/destructuring-assignment
        newType: false,
    };
    
    componentDidMount = () => {
        const { data, location, predefinedState } = this.props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        let overrideState = {};
        if(queryObject.userId) {
            overrideState.userId = queryObject.userId;
        }

        this.setState(prevState => ({
            formState: {
                ...prevState.formState,
                ...this.dataToFormState(data || {}),
                ...overrideState,    
                ...predefinedState,                
            },
        }));
    }

    componentDidUpdate = prevProps => {
        const { data } = this.props;

        if (data && JSON.stringify(data) !== JSON.stringify(prevProps.data)) {
            this.setState(prevState => ({
                formState: {
                    ...prevState.formState,
                    ...this.dataToFormState(data),
                },
            }));
        }
    }

    dataToFormState = ({ fieldsConfig }) => {
        let formState = {};

        for (let index in fieldsConfig) {
            formState[index] = fieldsConfig[index].enabled;
        }

        return formState;
    }

    formStateToData = ( formState ) => {
        const { data } = this.props;

        let tmp = {};
        for (let index in formState) {
            let originalObject = data.fieldsConfig[index];
            if(index !== 'userId') {
                tmp[index] = {
                    ...originalObject,
                    enabled: formState[index],
                };
            }
        }
        
        return tmp;
    }

    onSubmit = formState => {
        return this.onCreate(formState);
    }

    onCreate = formState => {
        const { actions, userId } = this.props;

        return actions.create({
            userId,
            fieldsConfig: this.formStateToData(formState),
        });
    }

    onRemove = (fieldConfig) => {
        const { actions, userId, loadData } = this.props;  

        if(window.confirm('Czy napewno chcesz usunąć ten typ pomiaru?')) {
            actions.removeType({ 
                key: fieldConfig,
                userId,
            }).then(() => {
                loadData();
                toast('Typ pomiaru został usunięty');
            });
        }
    }

    renderMeasurementConfigSwitches = ({ fieldsConfig }) => {
        const { formState } = this.state;

        return Object.keys(fieldsConfig).map(fieldConfigKey => {
            const fieldConfig = fieldsConfig[fieldConfigKey];

            return {
                type: fieldConfig.custom? 'switchWithFooter': 'switch' ,
                name: fieldConfigKey,
                label: fieldConfig.name,
                inputProps: {
                    value: formState[fieldConfigKey],
                },
                footer: fieldConfig.custom && (
                    <div className="measurement-config-box-footer">
                        <a 
                            className="measurement-config-box-footer-control"
                            onClick={() => this.onRemove(fieldConfigKey)}
                        >
                            Usuń
                        </a>
                    </div>
                ),
            };
        });
    }

    render() {
        const { data, location, history } = this.props;
        const { formState, newType } = this.state;

        if (!formState) {
            return (<Spinner />);
        }

        return (
            <StyledComponent
                className="admin-measurements-config-editor"
                styles={require('./styles')}
            >
                <ElementEditor
                    location={location}
                    history={history}
                    details={[{
                        visible: Boolean(data),
                        label: 'Identyfikator',
                        value: formState.id,
                    }]}
                    controls={[{
                        visible: true,
                        title: 'Nowy typ pomiarów',
                        subtitle: 'Możesz dodać nowy typ pomiarów',
                        buttonProps: {
                            onClick: () => this.setState({ newType: true }),
                            children: 'Dodaj',
                        },
                    }]}
                    forms={[{
                        title: 'Ustawienia',
                        name: 'editor',
                        submitAction: this.onSubmit,
                        data: formState,
                        onStateChange: formState => {
                            this.setState({
                                formState,
                            });
                        },
                        elements: this.renderMeasurementConfigSwitches(data),
                    }]}
                />
                <MeasurementConfigNewType
                    location={location}
                    history={history}
                    isOpen={Boolean(newType)}
                    onClose={() => this.setState({ newType: false })}
                    data={{ newType }}
                />
            </StyledComponent>
        );
    }
}

